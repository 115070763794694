import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from '../components/layout'
import baselogo from '../images/logo_base.png'

class TermsPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')  
    
    return (
      <Layout location={this.props.location} >
        <SEO title={siteTitle} keywords={[`artificial intelligence`,`machine learning`, `automation`, `rpa`, 'deep learning', 'ai']} />
        <div style={{ background: '#fff' }}>                               
          <div className="page_wrapper" style={{minHeight:'60vmin'}}>
            <h4 className='navbutton'><a href='/' >&#8249; Back </a></h4>
            <div style={{backgroundColor:"#909090"}}>
              <img className="legals_logo" src={baselogo} alt="" />
            </div>
            <h1 className="section-headline">Terms &amp; Conditions</h1>            
            <h3 style={{color: "#909090"}}>Site Content Disclaimer</h3>
            <ul>
                <li>TenTenths Digital Pty Ltd (‘The Company’) has taken all reasonable steps to ensure the accuracy of information on this website. However we can give no warranty regarding the accuracy or completeness of the content of this website. Hence we accept no liability for any losses or damages (whether direct, indirect, special, consequential or otherwise) arising out of errors or omissions contained in this web site.</li>
                <li>The Company reserves the right to update, add, amend, remove, replace or change any part of the web site content, including but not limited to functionality and navigation at any time without prior notice. The Company shall not be liable for distortion of data arising from any technical fault including transmission errors, technical defects, interruptions, third party intervention or viruses.</li>
            </ul>
            <h3 style={{color: "#909090"}}>Copyright Laws</h3>
            <ul>
                <li>The information you receive online from this site is protected by the copyright laws of Australia. The copyright laws prohibit any copying, redistributing, retransmitting, or repurposing of any copyright-protected material without express written permission from The Company or the relevant copyright owner. If given, permission will be subject to the requirement that the copyright owner’s name and interest in the material be acknowledged when the material is reproduced or quoted, in whole or in part.</li>
            </ul>            
            <h3 style={{color: "#909090"}}>Third Party Links</h3>
            <ul>
                <li>This website contains links to other web sites which are hosted and maintained by third parties. The Company makes no representation as to the accuracy, completeness or relevance of the information contained on such Third Party Sites. You follow links to such sites at your own risk, and we will not be liable for any loss or damage rising from your reliance upon or use of Third Party Sites.</li>
            </ul>
            <h3 style={{color: "#909090"}}>Exclusion Of Liability</h3>
            <ul>
                <li>The Company cannot guarantee that this web site shall be available on an uninterrupted basis and we will not be liable for any losses, costs or damages resulting from this web site not being accessible or for delays in access. Access to and use of this web site is at the user’s own risk and we cannot warrant that the use of this web site or any material downloaded from it will not cause damage to any property. In addition, we accept no liability in respect of losses or damages arising out of changes made to the content of this web site by unauthorised third parties.</li>
            </ul>            
            <hr style={{border: '1px solid #505050', maxWidth:"80vw"}}/>
          </div>           
        </div>
      </Layout>
    )
  }
}

export default TermsPage

export const pageQuery = graphql`
  query TermsPageIndexQuery {
    site {
      siteMetadata {
        title
      }
    }    
  }
`
